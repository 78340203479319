<template>
  <div class="mod-schedule">
    <el-form
      :inline="true"
      :model="searchForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-button
          v-if="isAuth('sys:schedule:log')"
          type="success"
          @click="logHandle()"
        >
          日志列表
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border style="width: 100%">
      <el-table-column
        show-tooltip-when-overflow
        prop="remark"
        header-align="center"
        align="center"
        label="名称"
      />
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small"> 正常 </el-tag>
          <el-tag v-else size="small" type="danger"> 暂停 </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              $dialog(
                'scheduleCompanyDialog',
                scope.row.jobId,
                scope.row.remark,
              )
            "
          >
            立即执行
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
    />
    <!-- 弹窗, 新增 / 修改 -->
    <schedule-company-dialog
      v-if="scheduleCompanyDialogVisible"
      ref="scheduleCompanyDialog"
    />
    <!-- 弹窗, 日志列表 -->
    <log v-if="logVisible" ref="log" />
  </div>
</template>

<script>
import ScheduleCompanyDialog from './schedule-company-dialog';
import Log from './schedule-log';
import { listMixin, normal } from '@/mixins';

export default {
  mixins: [listMixin, normal],
  components: {
    ScheduleCompanyDialog,
    Log,
  },
  data() {
    return {
      searchForm: {
        beanName: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      scheduleCompanyDialogVisible: false,
      logVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/schedule/jobcompany/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          beanName: this.searchForm.beanName,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },

    // 立即执行
    //   runHandle(id) {
    //     let ids = id
    //       ? [id]
    //       : this.dataListSelections.map((item) => {
    //           return item.jobId;
    //         });
    //     this.$prompt('请选择公司','提示',{
    //       confirmButtonText: '确定',
    //       cancelButtonText:'取消',
    //       inputPattern:'',
    //     }).then(() => {
    //       this.$http({
    //         url: '/sys/schedule/runCompany',
    //         method: 'post',
    //         data: {
    //           jobId: id,
    //           companyIds: ''
    //         },
    //       }).then(({ data }) => {
    //         if (data && data.code === 0) {
    //           this.$message({
    //             message: '操作成功',
    //             type: 'success',
    //             duration: 1500,
    //             onClose: () => {
    //               this.getDataList();
    //             },
    //           });
    //         }
    //       });
    //       })
    //       .catch(() => {});
    //   },
    // 日志列表
    logHandle() {
      this.logVisible = true;
      this.$nextTick(() => {
        this.$refs.log.init();
      });
    },
  },
};
</script>
